html,
body {
  height: 100%;
}

body>#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.margin-bottomNav {
  margin-bottom: 56px;
}

.text-caps {
  text-transform: capitalize !important;
}

/* bootstrap styles */

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

/* spinner */

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner .75s linear infinite;
}

.spinner-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.scanner {
  /* height: 500px; */
  position: relative;
}

.scanner::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20px;
  opacity: 0.8;
  background: #06FF00;
  box-shadow: 0 0 200px 20px #06FF00;
  clip-path: inset(0);
  animation:
    x 0.5s ease-in-out infinite alternate,
    y 1s ease-in-out infinite;
}

@keyframes x {
  to {
    transform: translateX(-100%);
    left: 100%;
  }
}

@keyframes y {
  33% {
    clip-path: inset(0 0 0 -100px);
  }

  50% {
    clip-path: inset(0 0 0 0);
  }

  83% {
    clip-path: inset(0 -100px 0 0);
  }
}